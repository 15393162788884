<template>
    <div>
        <Dialog
            :visible.sync="showDialog"
            position="center"
            :modal="true"
            :closeOnEscape="true"
            :closable="true"
            class="form-templates-edit-dialog"
            :contentStyle='{ overflow: "visible" }'
        >
            <template #header>
                <div class="p-mb-0 dialog-title p-px-2">{{ dialogTitle }}</div>
            </template>

            <div class="p-d-flex p-flex-column p-field p-col-12 p-py-0 p-mb-0 p-px-2">
                <label>Наименование шаблона* </label>
                <InputText
                    id="system"
                    type="text"
                    v-model="formData.name"
                    placeholder=""
                />
                <small
                    v-for="error in showErrors(v$.formData.name)"
                    :key="error.$uid"
                    class="p-error"
                >{{ error.$message }}</small>
            </div>
            <div class="p-d-flex p-col-12 p-lg-12 p-mb-lg-0 p-pb-0 p-px-2">
                <div class="p-field p-d-flex p-flex-column p-col-6 p-md-6 p-py-0 p-px-0">
                    <label for="calendarStart">Год*</label>
                    <Dropdown
                        v-model='formData.year'
                        :options='years'
                        filter
                    />
                </div>
            </div>
            <div v-if="currentDialogMode !== 'COPY'"
                class="p-field-checkbox p-d-flex p-ai-center p-field p-col-12 p-pb-3 p-pt-0 p-mb-0 p-px-2"
            >
                <Checkbox id="binary" v-model="formData.active" :binary="true" />
                <label for="binary" class="p-my-0 p-mr-0">Активный</label>
            </div>
            <template #footer>
                <div class="p-d-flex p-jc-start">
                    <Button
                        class="p-button"
                        type="button"
                        @click="saveButtonHandler"
                        :disabled="formData.name.length < 3"
                    >
                        <span class="p-button-label">Сохранить</span>
                        <span class="p-ink"></span>
                    </Button>

                    <Button class="p-button p-button-outlined" @click="closeCreateOrEditTemplateDialog">
                        <span class="p-button-label black">Отменить</span>
                    </Button>
                </div>
            </template>
        </Dialog>
        <DataTable
            :value="formTemplates"
            :paginator="true"
            class="p-datatable-customers p-datatable-striped form-templates-list"
            :class="{ showFilters: showFilters }"
            :rows="20"
            :rowsPerPageOptions="rowsPerPageOptions"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate=""
            :totalRecords="totalRecords"
            dataKey="id"
            selectionMode="single"
            :filters="filters"
            :scrollable='true'
            scroll-height='calc(100vh - 450px)'
            resizable-columns
            @row-dblclick="rowClicked"
        >
            <template #header>
                <div class="table-header p-flex-wrap p-d-flex">
                    <div class="p-col-5 p-p-0 p-d-flex p-ai-center"></div>
                    <div class="p-col-7 p-p-0 p-d-flex p-ai-center p-jc-end table-global-search">
                        <div class="p-inputgroup p-p-0 p-d-flex table-global-search__input">
                        <span class="p-float-label">
                            <InputText type="text" v-model="filters['global']" placeholder="Поиск" />
                        </span>
                            <span class="p-inputgroup-addon">
                            <i class="pi pi-search"></i>
                        </span>
                        </div>
                        <div class="p-p-0 p-new-btn">
                            <Button @click="openCreateOrEditTemplateDialog('CREATE')" class="p-button"> Добавить </Button>
                        </div>
                    </div>
                </div>
            </template>
            <template #empty> Шаблоны форм не найдены</template>
            <Column
                header='Наименование шаблона'
                sortable
                field='name'
                data-key='start-year'
                header-class='header-name'
                body-class='body-name'
            >
                <template #body="slotProps">
                    <div :title='slotProps.data.name' class="p-text-nowrap p-text-truncate">{{ slotProps.data.name }}</div>
                </template>
                <template v-if="showFilters" #filter>
                    <InputText v-show="showFilters" type="text" v-model="filters['name']" class="p-column-filter p-my-2" placeholder="Наименование" />
                </template>
            </Column>
            <Column
                header='Год'
                sortable
                field='year'
                header-class='header-date-start'
                body-class='body-date-start'
            >
<!--                <template #body="slotProps">-->
<!--                    <div :title='slotProps.data.name' class="p-text-nowrap p-text-truncate">{{ slotProps.data.name }}</div>-->
<!--                </template>-->
                <template v-if="showFilters" #filter>
                    <InputText v-show="showFilters" type="text" v-model="filters['year']" class="p-column-filter p-my-2" placeholder="Год" />
                </template>
            </Column>
            <Column
                header='Статус'
                sortable
                field='status'
                header-class='header-status'
                body-class='body-status'
            >
                <template #body='slotProps'>
                <span
                    :title='getStatus(slotProps).title'
                    :style='`background: ${getStatus(slotProps).bgColor}; padding: 4px; border-radius: 4px;`'
                >
                    {{ getStatus(slotProps).label }}
                </span>
                </template>
                <template #filter v-if='showFilters'>
                    <Dropdown
                        v-model="filters['status']"
                        :options="formTemplateStatuses"
                        placeholder="Статус"
                        option-value='value'
                        option-label='label'
                        class="p-column-filter"
                        :showClear="true"
                        @click.stop
                    >
                    </Dropdown>
                </template>
            </Column>
            <Column header="" header-class="p-text-center" body-style="position: relative; width: 52px; text-align: center;" header-style="width: 52px;">
                <template #body="slotProps">
                    <div class="p-panel-header-icon p-link" @click.stop="toggleRowMenu($event, slotProps)">
                        <span class="pi pi-ellipsis-h"></span>
                    </div>
                    <Menu
                        class="redLastListElement"
                        :ref="`listMenu${slotProps.data.id}`"
                        :model="listMenuItems"
                        :popup="true"
                        :baseZIndex="10"
                        :class='getHideClass(slotProps.data.status)'
                    />
                    <!--                <Button icon="pi pi-pencil" class="p-button-rounded p-button-secondary p-button-text" @click.stop="editRole(slotProps.data.id)" />-->
                    <!--                <Button v-if="!guardRoles.find((role) => slotProps.data.id == role)" icon="pi pi-times" class="p-button-rounded p-button-secondary p-button-text p-ml-1" @click="removeRole(slotProps.data.id)" />-->
                </template>

                <template #header>
                    <Button icon="pi pi-filter" :class="filterClasses" class="p-button-rounded p-button-outlined filter-btn" @click="showFilters = !showFilters" />
                </template>
            </Column>
            <template #paginatorLeft></template>
        </DataTable>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
    createNewFormTemplate,
    copyFormTemplate,
    getFormTemplates,
    patchFormTemplate,
    downloadConstructorTemplateXLS,
    downloadCheckLayers
} from '@/api/form/formsTemplates';
import { requestToastHandler } from '@/main/mixins';
import { jsonApiListParser } from '@/main/utils/common';
import {
    FORM_STATUSES_NAMES,
    FORM_TEMPLATES_DIALOG_MODES,
    FORM_TEMPLATES_STATUS_NAMES,
    FORM_TEMPLATES_STATUSES,
} from '@/constants/forms';
import * as XLSX from 'xlsx/xlsx';
import { convertLayer } from '@/utils/common';
import FormTemplates from '@/models/FormTemplates';
import SubmitJsonApi from '@/models/SubmitJsonApi';
import { useVuelidate } from '@vuelidate/core';
import { minLength } from '@vuelidate/validators';
import { cloneFormTemplate } from '@/main/utils/formTemplates';

const { ACTIVE, INACTIVE } = FORM_TEMPLATES_STATUS_NAMES

export default {
    name: 'formTemplates',
    mixins: [requestToastHandler],
    props: {
        itemId: {
            type: [String, Number],
            default: ''
        }
    },
    async created() {
        if (this.itemId) {
            await this.getData();
        }
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
        return {
            formData: {
                name: {
                    minLength: { ...minLength(3), $message: 'Значение не должно быть короче 3 символов' }
                }
            }
        }
    },
    data() {
        this.statuses = ['активная', 'архивная', 'новая']
        this.formTemplateStatuses = FORM_TEMPLATES_STATUSES
        const { CREATE, COPY, EDIT } = FORM_TEMPLATES_DIALOG_MODES
        this.saveActionsMap = {
            [CREATE.NAME]: () => this.createFormTemplate(),
            [EDIT.NAME]: () => this.patchCurrentFormTemplate(),
            [COPY.NAME]: () => this.copyFormTemplate()
        }
        this.curYear = new Date().getFullYear()
        this.years = []
        for (let i = this.curYear; i !== 1971; i--) {
            this.years.push(i.toString())
        }
        return {
            templateFileInput: null,
            templateFile: null,
            templateFileName: '',
            currentDialogMode: FORM_TEMPLATES_DIALOG_MODES.CREATE.NAME,
            formTemplates: [],
            dateStart: null,
            showDialog: false,
            currentTemplateStatus: null,
            formTemplateTitle: '',
            dialogMode: '',
            editItemId: null,
            fileName: null,
            filters: {},
            showFilters: false,
            totalRecords: null,
            listMenuItems: [
                {
                    label: 'Конструктор шаблонов',
                    icon: 'pi pi-cog',
                    command: () => {
                        this.openConstructor()
                    },
                },
                {
                    label: 'Копировать шаблон',
                    icon: 'pi pi-clone',
                    command: () => {
                        this.copyItem()
                    },
                },
                {
                    label: 'Скачать шаблон',
                    icon: 'pi pi-fw pi-download',
                    command: () => {
                        this.downloadConstructorTemplateXLS();
                    },
                },
                {
                    label: 'Скачать шаблон с sharedId',
                    icon: 'pi pi-fw pi-download',
                    command: () => {
                        this.downloadConstructorTemplateXLS('xlsx?showSharedId=1');
                    },
                },
                {
                    label: 'Скачать проверки',
                    icon: 'pi pi-fw pi-download',
                    command: () => {
                        this.downloadChecksXLS();
                    },
                },
                {
                    label: 'Сделать активным',
                    icon: 'pi icon custom-check-square',
                    command: () => {
                        this.changeStatus(ACTIVE)
                    },
                    class: 'active'
                },
                {
                    label: 'Сделать архивным',
                    icon: 'pi icon custom-check-square',
                    command: () => {
                        this.changeStatus(INACTIVE)
                    },
                    class: 'inactive'
                },
            ],
            formData: new FormTemplates({ year: this.curYear.toString() })
        }
    },
    computed: {
        ...mapGetters(['rowsPerPageOptions']),
        filterClasses() {
            return this.showFilters ? '' : 'p-button p-component p-button-outlined';
        },
        currentStatusProp: {
            get() {
                const { active, archive } = this.formData
                const { ARCHIVE, ACTIVE, DRAFT } = FORM_STATUSES_NAMES
                if (!active && !archive) return DRAFT
                if (active && !archive) return ACTIVE
                return ARCHIVE
            },
            set(val) {
                const { ARCHIVE, ACTIVE, DRAFT } = FORM_STATUSES_NAMES
                switch (val) {
                    case DRAFT:
                        this.formData.active = false
                        this.formData.archive = false
                        break
                    case ACTIVE:
                        this.formData.active = true
                        this.formData.archive = false
                        break
                    case ARCHIVE:
                        this.formData.active = false
                        this.formData.archive = true
                }
            }
        },
        dialogTitle() {
            return FORM_TEMPLATES_DIALOG_MODES[this.currentDialogMode].TITLE
        },
        dialogModeTitle() {
            let title = '';
            if (this.dialogMode === 'Добавить') title = 'Добавить шаблон';
            if (this.dialogMode === 'Редактировать') title = 'Редактировать шаблон';
            if (this.dialogMode === 'Копировать') title = 'Копировать шаблон';
            return title;
        },
    },
    methods: {
        async downloadChecksXLS() {
            try {
                this.$emit('loadingChange', true);
                const { data: { data: checkData, included: checkIncluded } } = await downloadCheckLayers(this.editItemId);

                let worksheetData = {};
                let header = [
                    'Идентификатор проверки',
                    'Код ячейки',
                    'Идентификатор шаблона',
                    'Название листа',
                    'Графа',
                    'Строка',
                    'Проверка'
                ];

                if (checkData.length === 0) {
                    this.$toast.add({
                        severity: 'info',
                        summary: 'Проверки не найдены.',
                        life: 2500,
                    });
                    return;
                }

                checkData?.forEach((check) => {
                    let cell = checkIncluded.find((item) => item.id === check.relationships.cell.data.id);
                    let worksheet = checkIncluded.find((item) => item.id === cell.relationships.worksheet.data.id);
                    let template = checkIncluded.find((item) => item.id === worksheet.relationships.template.data.id);

                    if (!worksheetData[worksheet.attributes.shortName]) {
                        worksheetData[worksheet.attributes.shortName] = [header];
                    }

                    worksheetData[worksheet.attributes.shortName].push([
                        check.id,
                        check.relationships.cell.data.id,
                        template.id,
                        worksheet.attributes.shortName,
                        worksheet.attributes.properties.columnLabels
                            ? worksheet.attributes.properties.columnLabels[cell.attributes.column-1] : cell.meta.coordinate[0],
                        worksheet.attributes.properties.rowLabels
                            ? worksheet.attributes.properties.rowLabels[cell.attributes.row-1] : cell.attributes.row,
                        convertLayer({
                            layer: check.attributes.value,
                            included: checkIncluded
                        })
                    ]);
                });

                let workbook = XLSX.utils.book_new();
                Object.entries(worksheetData).forEach(([sheetName, sheetData]) => {
                    let worksheet = XLSX.utils.aoa_to_sheet(sheetData);
                    XLSX.utils.book_append_sheet(workbook, worksheet, `${ sheetName }`);
                })
                XLSX.writeFileXLSX(workbook, 'checks.xlsx');
            } catch(error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange');
            }
        },

        async downloadConstructorTemplateXLS(format = 'xlsx') {
            this.$emit('loadingChange', true);
            await downloadConstructorTemplateXLS(this.editItemId, this.fileName, format);
            this.$emit('loadingChange');
        },
        async changeStatus(newStatus) {
            const active = newStatus === ACTIVE
            const item = {
                ...cloneFormTemplate(this.editItemId, this.formTemplates),
                active
            }
            try {
                this.$emit('loadingChange', true);
                const result = await patchFormTemplate(this.editItemId, new SubmitJsonApi(item, 'form-template', this.editItemId));
                if (result) await this.getData();
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange', false);
            }
            // const distInterval = {
            //     id: this.selectedForm.distributionInterval.id,
            //     type: 'form-distribution-interval'
            // }
            // const data = {
            //     ...this.selectedForm,
            //     ...statusFieldsMap[newStatus],
            //     id: this.editItemId,
            //     distributionInterval: distInterval
            // }
            // try {
            //     this.$emit('loadingChange', true)
            //     await editFormParametrs(this.editItemId, new SubmitJsonApi(data, 'form'))
            //     const formIdx = this.forms.findIndex(({ id }) => id === this.editItemId)
            //     this.$set(this.forms, formIdx, { ...this.selectedForm, ...statusFieldsMap[newStatus], status: newStatus })
            // } catch (error) {
            //     this.$requestError(error.message)
            // } finally {
            //     this.$emit('loadingChange', false)
            // }
        },
        getHideClass(itemStatus) {
            const classMap = {
                [ACTIVE]: 'hide-active',
                [INACTIVE]: 'hide-inactive'
            }
            return classMap[itemStatus]
        },
        openFileInput() {
            console.log('REFS', this.$refs.fileInput);
            this.$refs.fileInput.$el.click()
        },
        saveButtonHandler() {
            this.saveActionsMap[this.currentDialogMode]()
        },
        openCreateOrEditTemplateDialog(mode) {
            this.currentDialogMode = mode;
            this.showDialog = true;
        },
        closeCreateOrEditTemplateDialog() {
            this.currentDialogMode = '';
            this.formTemplateTitle = '';
            this.currentTemplateId = null;
            this.currentTemplateStatus = null;
            this.dateStart = null;
            this.showDialog = false;
        },

        async copyFormTemplate() {
            this.$emit('loadingChange', true);
            try {
                if (typeof this.formData.year === 'object') {
                    this.formData.year = this.formData.year.getFullYear();
                }
                this.formData.active = false;
                const data = new SubmitJsonApi(this.formData, 'form-template');
                const result = await copyFormTemplate(this.editItemId, data);
                if (result) {
                    await this.getData();
                }
                this.closeCreateOrEditTemplateDialog();
            } catch(error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange');
            }
        },

        async createFormTemplate() {
            // const dataToServer = {
            //     data: {
            //         type: 'form-template',
            //         id: null,
            //         attributes: {
            //             name: this.formTemplateTitle,
            //             code: 'string',
            //             year: 2021,
            //             active: this.currentTemplateStatus,
            //             createdAt: this.dateStart,
            //         },
            //         relationships: {
            //             form: {
            //                 data: {
            //                     type: 'string',
            //                     id: this.itemId,
            //                 },
            //             },
            //         },
            //     },
            // };
            if (typeof this.formData.year === 'object') this.formData.year = this.formData.year.getFullYear()
            console.log('FORM DATA', this.formData);
            const data = new SubmitJsonApi(this.formData, 'form-template')
            try {
                this.$emit('loadingChange', true);
                const { data: { data: { id } } } = await createNewFormTemplate(this.itemId, data);
                // console.log('RESULT', id);
                // await uploadFormTemplateFile(id, this.templateFile)
                if (id) await this.getData();
                this.closeCreateOrEditTemplateDialog();
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange', false);
            }
        },
        async patchCurrentFormTemplate() {
            if (typeof this.formData.year === 'object') this.formData.year = this.formData.year.getFullYear()
            console.log('FORM DATA', this.formData);
            const data = new SubmitJsonApi(this.formData, 'form-template')
            // const dataToServer = {
            //     data: {
            //         type: 'form-template',
            //         id: this.currentTemplateId,
            //         attributes: {
            //             name: this.formTemplateTitle,
            //             code: 'string',
            //             year: 2021,
            //             active: this.currentTemplateStatus,
            //             createdAt: this.dateStart,
            //         },
            //         relationships: {
            //             form: {
            //                 data: {
            //                     type: 'string',
            //                     id: this.itemId,
            //                 },
            //             },
            //         },
            //     },
            // };
            try {
                this.$emit('loadingChange', true);
                const result = await patchFormTemplate(this.editItemId, data);
                if (result) await this.getAllFormTemplates(1, 5000);
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange', false);
            }

            this.closeCreateOrEditTemplateDialog();
        },
        async getData() {
            try {
                this.$emit('loadingChange', true);
                const { data, included } = await getFormTemplates({ formId: this.itemId, relationShip: true })
                this.formTemplates = jsonApiListParser(data, included);
                this.formTemplates.forEach(i => {
                    const { active } = i
                    if (active) this.$set(i, 'status', 'active')
                    else this.$set(i, 'status', 'inactive')
                })
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange');
            }
        },
        editItem() {
            const item = this.formTemplates.find(({ id }) => id === this.editItemId)
            this.formData = new FormTemplates(item)
            this.openCreateOrEditTemplateDialog(FORM_TEMPLATES_DIALOG_MODES.EDIT.NAME);
        },
        copyItem() {
            this.formData = cloneFormTemplate(this.editItemId, this.formTemplates)
            this.openCreateOrEditTemplateDialog(FORM_TEMPLATES_DIALOG_MODES.COPY.NAME)
        },
        rowClicked({ data }) {
            this.editItemId = data.id
            this.openConstructor()
        },
        toggleRowMenu(event, slotProps) {
            const { data: { id, name } } = slotProps

            if (id !== this.editItemId) {
                this.$refs[`listMenu${this.editItemId}`] && this.$refs[`listMenu${this.editItemId}`].hide(event)
            }
            this.editItemId = id;
            this.fileName = name;

            this.$refs[`listMenu${id}`].toggle(event);
        },
        getStatus (slotProps) {
            const status = this.formTemplateStatuses.find(item =>  item.value === slotProps.data.status)
            return status || {}
        },
        openConstructor() {
            this.$router.push(`/forms/constructor/${ this.itemId }/${ this.editItemId }`)
        },
        showErrors(data) {
            return data.$errors.length ? data.$errors : data.$silentErrors;
        },
    },
    watch: {
        showDialog: {
            handler(to) {
                if (!to) {
                    this.formData = new FormTemplates({ year: this.curYear })
                }
            }
        },
        templateFileInput: {
            handler(to) {
                if (to) {
                    const file = this.$refs.fileInput.$el.files[0]
                    const fD = new FormData()
                    fD.append('file', file)
                    this.templateFileName = file.name
                    this.templateFile = fD
                }
            }
        }
    }
};
</script>

<style scoped lang='scss'>
.form-templates-dialog {
    width: 37.5vw;
    .dialog-title {
        font-size: 18px;
    }
}
.form-templates-list {
    .p-datatable {
        &-header {
            .table-global-search {
                margin-top: 0 !important;
            }
        }
    }

    ::v-deep {
        .header, .body {
            &-date-start, &-status {
                width: 10vw;
            }
        }
    }
}
::v-deep {
    .hide {
        &-active {
            .active {
                display: none!important;
            }
        }
        &-inactive {
            .inactive {
                display: none!important;
            }
        }
    }
}
</style>
