<template>
    <div>
        <Dialog
            :visible.sync="showDialog"
            position="center"
            :modal="true"
            :closeOnEscape="true"
            :closable="true"
            :contentStyle='{ overflow: "visible" }'
            class="report-templates-edit-dialog"
        >
            <template #header>
                <div class="p-mb-0 dialog-title p-px-0">{{ dialogTitle }}</div>
            </template>
            <div class="p-d-flex p-flex-column p-field p-col-12 p-py-0 p-mb-0 p-px-0">
                <label>Название шаблона* </label>
                <InputText
                    type="text"
                    v-model="formData.name"
                    placeholder=""
                />
                <small
                    v-show="submitted"
                    v-for="error in showErrors(v$.formData.name)"
                    :key="error.$uid"
                    :class="v$.formData.name.$invalid && submitted ? 'p-error' : ''"
                >{{ error.$message }}</small>
            </div>
            <div class="p-d-flex p-flex-column p-field p-col-12 p-py-0 p-mb-0 p-px-0">
                <label>Год*</label>
                <Dropdown
                    v-model="formData.year"
                    :options="years"
                    filter
                    option-label="name"
                    placeholder="Выберите год"
                />
            </div>
            <div
                class="p-field-checkbox p-d-flex p-ai-center p-field p-col-12 p-pb-3 p-pt-0 p-mb-0 p-px-0"
            >
                <Checkbox id="binary" v-model="formData.active" :binary="true" />
                <label for="binary" class="p-my-0 p-mr-0">Активный</label>
            </div>
            <div class="p-d-flex p-flex-column p-col-12 p-py-0 p-mb-0 p-px-0">
                <label>Файл шаблона*</label>
                <div class="p-d-flex p-jc-start p-ai-center uploadItem p-py-1">
                    <Button
                        :label="uploadFile.file ? 'Изменить файл ' : 'Загрузить файл'"
                        icon="pi pi-upload"
                        iconPos="left"
                        class="uploadwidth p-mr-2"
                        @click="choiceFile(uploadFile.id)"
                    />
                    <span class="fileNameLength">{{ uploadFile.file && uploadFile.file.name }}</span>
                    <input
                        :ref="`uploadItem${uploadFile.id}`"
                        type="file"
                        @change="onFileSelect($event)"
                        class="uploadInput"
                    />
                    <Button
                        icon="pi pi-times-circle"
                        class="p-button-raised p-button-danger p-button-text"
                        @click="removeFile"
                        v-if="uploadFile.file"
                    />
                </div>
                <small
                    v-show="submitted && !uploadFile.file"
                    :class="submitted && !uploadFile.file ? 'p-error' : ''"
                >Файл является обязательным</small>
                <small
                    v-show="submitted && uploadFile.file && !acceptTypes.test(uploadFile.file.name)"
                    :class="submitted && uploadFile.file && !acceptTypes.test(uploadFile.file.name) ? 'p-error' : ''"
                >Формат файла должен быть .xlsx или .docx</small>
            </div>
            <template #footer>
                <div class="p-d-flex p-jc-start">
                    <Button
                        class="p-button"
                        type="button"
                        @click="saveFormReportTemplate"
                    >
                        <span class="p-button-label">{{ buttonLabel }}</span>
                    </Button>
                    <Button class="p-button p-button-outlined" @click="closeDialog">
                        <span class="p-button-label black">Отменить</span>
                    </Button>
                </div>
            </template>
        </Dialog>
        <DataTable
            :value="reportTemplates"
            :scrollable="true"
            :paginator="true"
            :lazy="true"
            class="p-datatable-customers p-datatable-striped report-templates-list"
            :class="{ showFilters: showFilters }"
            :rows="pageSize"
            :rowsPerPageOptions="rowsPerPageOptions"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate=""
            :totalRecords="totalRecords"
            dataKey="id"
            selectionMode="single"
            :filters="filters"
            scroll-height='calc(100vh - 350px)'
            resizable-columns
            filterMode="lenient"
            @page="onPage"
        >
            <template #header>
                <div class="table-header p-flex-wrap p-d-flex">
                    <div class="p-col-5 p-p-0 p-d-flex p-ai-center"></div>
                    <div class="p-col-7 p-p-0 p-d-flex p-ai-center p-jc-end table-global-search">
                        <div class="p-inputgroup p-p-0 p-d-flex table-global-search__input">
                            <span class="p-float-label">
                                <InputText
                                    type="text"
                                    v-model="filters['global']"
                                    placeholder="Поиск"
                                />
                            </span>
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-search"></i>
                            </span>
                        </div>
                        <div class="p-p-0 p-new-btn">
                            <Button @click="openAddDialog" class="p-button">Добавить</Button>
                        </div>
                    </div>
                </div>
            </template>
            <template #empty>Шаблоны отчётов не найдены.</template>
            <Column
                header='Наименование'
                sortable
                field='name'
                data-key='start-year'
                header-class='header-name'
                body-class='body-name'
            >
                <template #body="slotProps">
                    <div :title='slotProps.data.name' class="p-text-nowrap p-text-truncate">{{ slotProps.data.name }}</div>
                </template>
                <template v-if="showFilters" #filter>
                    <InputText v-show="showFilters" type="text" v-model="filters['name']" class="p-column-filter p-my-2" placeholder="Поиск" />
                </template>
            </Column>
            <Column
                header='Год'
                sortable
                field='year'
                header-class='header-year'
                body-class='body-year'
            >
                <template #body="slotProps">
                    <div :title='slotProps.data.year' class="p-text-nowrap p-text-truncate">{{ slotProps.data.year }}</div>
                </template>
                <template v-if="showFilters" #filter>
                    <InputText v-show="showFilters" type="text" v-model="filters['year']" class="p-column-filter p-my-2" placeholder="Поиск" />
                </template>
            </Column>
            <Column
                header='Статус'
                sortable
                field='status'
                header-class='header-status'
                body-class='body-status'
            >
                <template #body='slotProps'>
                <span
                    :title='getStatus(slotProps.data.active).title'
                    :style='`background: ${getStatus(slotProps.data.active).bgColor}; padding: 4px; border-radius: 4px;`'
                >
                    {{ getStatus(slotProps.data.active).label }}
                </span>
                </template>
                <template #filter v-if='showFilters'>
                    <Dropdown
                        v-model="filters['status']"
                        :options="formTemplateStatuses"
                        placeholder="Поиск"
                        option-value='value'
                        option-label='label'
                        class="p-column-filter p-my-2"
                        :showClear="true"
                        @click.stop
                    />
                </template>
            </Column>
            <Column
                header='Дата изменения'
                sortable
                header-class='header-date-update'
                body-class='body-date-update'
                field='updatedAt'
            >
                <template #body="slotProps">
                    <div :title='slotProps.data.updatedAt' class="p-text-nowrap p-text-truncate">{{ slotProps.data.updatedAt | toLocaleDate }}</div>
                </template>
            </Column>
            <Column header="" header-class="p-text-center" body-style="position: relative; width: 52px; text-align: center;" header-style="width: 52px;">
                <template #body="slotProps">
                    <div class="p-panel-header-icon p-link" @click.stop="toggleRowMenu($event, slotProps)">
                        <span class="pi pi-ellipsis-h"></span>
                    </div>
                    <Menu class="redLastListElement" :ref="`listMenu${slotProps.data.id}`" :model="listMenuItems" :popup="true" :baseZIndex="10" />
                </template>
                <template #header>
                    <Button icon="pi pi-filter" :class="filterClasses" class="p-button-rounded p-button-outlined filter-btn" @click="showFilters = !showFilters" />
                </template>
            </Column>
            <template #paginatorLeft></template>
        </DataTable>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { requestToastHandler } from '@/main/mixins';
import { downloadURI, jsonApiListParser } from '@/main/utils/common';
import {
    FORM_TEMPLATES_STATUSES,
    REPORT_TEMPLATES_DIALOG_MODES,
} from '@/constants/forms';
import ReportTemplate from '@/models/ReportTemplate';
import { useVuelidate } from '@vuelidate/core';
import { minLength, required } from '@vuelidate/validators';
import { v4 as uuidv4 } from 'uuid';
import { uploadFile } from '@/api/common';
import {
    getReportTemplates,
    createReportTemplate,
    updateReportTemplate,
    deleteReportTemplate,
    downloadReportTemplate
} from '@/api/form/reportTemplates';

export default {
    name: 'reportTemplates',

    mixins: [requestToastHandler],

    props: {
        itemId: {
            type: [String, Number],
            default: ''
        }
    },

    async created() {
        if (this.itemId) {
            await this.getData();
        }
    },

    setup: () => ({ v$: useVuelidate() }),

    validations() {
        return {
            formData: {
                name: {
                    required: { ...required, $message: 'Поле обязательно к заполнению' },
                    minLength: { ...minLength(3), $message: 'Значение не должно быть короче 3 символов' }
                }
            }
        }
    },

    data() {
        this.acceptTypes = /\.(xlsx|docx)$/i;
        this.statuses = ['активная', 'архивная'];
        this.formTemplateStatuses = FORM_TEMPLATES_STATUSES;
        this.currentYear = new Date().getFullYear();
        this.years = [];
        for (let i = this.currentYear; i !== 1971; i--) {
            this.years.push({ name: i.toString(), code: i });
        }

        return {
            templateFile: null,
            formData: new ReportTemplate({ year: { name: this.currentYear.toString(), code: this.currentYear } }),
            currentDialogMode: REPORT_TEMPLATES_DIALOG_MODES.CREATE.NAME,
            reportTemplates: [],
            showDialog: false,
            showDeleteTemplateDialog: false,
            currentReportId: null,
            filters: {},
            showFilters: false,
            currentPage: 1,
            pageSize: 20,
            totalRecords: null,
            submitted: false,
            uploadFile: {
                label: 'Загрузить файл',
                id: uuidv4(),
                file: null,
            },
            listMenuItems: [
                {
                    label: 'Редактировать',
                    icon: 'pi pi-pencil',
                    command: () => {
                        this.editReportTemplate();
                    },
                },
                {
                    label: 'Скачать',
                    icon: 'pi pi-download',
                    command: () => {
                        const { file } = this.selectedRowItem;
                        downloadURI(this.selectedRowItem.name, file.uri.split('.').pop(), file.uri);
                    },
                },
                {
                    label: 'Скачать без системных полей',
                    icon: 'pi pi-download',
                    command: () => {
                        downloadReportTemplate(this.selectedRowItem.id, this.selectedRowItem.name);
                    },
                },
                {
                    label: 'Удалить',
                    icon: 'pi pi-trash',
                    command: () => {
                        this.$root.$emit('showAcceptDeleteDialog', {
                            acceptAction: () => this.removeReport(this.currentReportId),
                            cancelAction: () => this.currentReportId = null
                        })
                    },
                    class: 'remove-row',
                },
            ],
        }
    },

    filters: {
        toLocaleDate(val) {
            return new Date(val).toLocaleDateString()
        }
    },

    computed: {
        ...mapGetters(['rowsPerPageOptions']),

        selectedRowItem() {
            return this.reportTemplates.find(i => i.id === this.currentReportId)
        },

        filterClasses() {
            return this.showFilters ? '' : 'p-button p-component p-button-outlined';
        },

        dialogTitle() {
            return REPORT_TEMPLATES_DIALOG_MODES[this.currentDialogMode].TITLE
        },

        buttonLabel() {
            return REPORT_TEMPLATES_DIALOG_MODES[this.currentDialogMode].BUTTON
        }
    },
    methods: {
        onPage(event) {
            setTimeout(() => {
                this.currentPage = event.page + 1;
                this.pageSize = event.rows;
                this.getData();
            }, 1000);
        },

        choiceFile(itemIndex) {
            this.$refs[`uploadItem${ itemIndex }`].click();
        },

        onFileSelect(event) {
            if (!this.uploadFile.file) {
                this.uploadFile = {
                    label: 'Загрузить файл',
                    id: uuidv4(),
                    file: null,
                };
            }
            this.uploadFile.file = event.target.files[0];
            this.formData.type = this.uploadFile.file.name.split('.').pop();
        },

        removeFile() {
            this.uploadFile.file = null;
        },

        async saveFormReportTemplate() {
            this.submitted = true;

            if (this.v$.$invalid || !this.uploadFile.file) {
                return;
            }

            try {
                this.$emit('loadingChange', true);
                const fileArray = await this.checkFileForUpload();
                const dataToServer = {
                    data: {
                        type: 'form-report-template',
                        id: this.currentDialogMode === 'EDIT' ? this.currentReportId : null,
                        attributes: {
                            name: this.formData.name,
                            type: this.formData.type === 'xlsx' ? this.formData.type : 'word',
                            active: this.formData.active ? this.formData.active : false,
                            year: this.formData.year.code
                        },
                        relationships: {}
                    },
                };
                if (fileArray.length) {
                    dataToServer.data.relationships.file = {
                        data: {
                            type: 'file',
                            id: fileArray[0].id,
                        },
                    };
                } else {
                    dataToServer.relationships.file = { data: {} };
                }

                let result;
                if (this.currentDialogMode === 'CREATE') {
                    result = await createReportTemplate(this.itemId, dataToServer);
                } else {
                    result = await updateReportTemplate(this.currentReportId, dataToServer);
                }

                if (result.message) {
                    this.$requestError(result.message);
                    return;
                }

                if (result) {
                    this.closeDialog();
                    await this.getData();
                }
            } catch (error) {
                this.$requestError(error.message);
                return;
            }
        },

        async removeReport(reportId) {
            try {
                this.$emit('loadingChange', true);

                const response = await deleteReportTemplate(reportId);
                if (response.message) {
                    this.$requestError(response.message);
                    return;
                }

                this.currentReportId = null;
                await this.getData();
                this.$emit('loadingChange');
            } catch (error) {
                this.$emit('loadingChange');
                this.$requestError(error.message);
            }
        },

        openAddDialog() {
            this.formData = new ReportTemplate({ year: { name: this.currentYear.toString(), code: this.currentYear } });
            this.uploadFile = {
                label: 'Загрузить файл',
                id: uuidv4(),
                file: null,
            };
            this.submitted = false;
            this.currentDialogMode = 'CREATE';
            this.showDialog = true;
        },

        closeDialog() {
            this.showDialog = false;
            this.uploadFile = {
                label: 'Загрузить файл',
                id: uuidv4(),
                file: null,
            };
            this.formData = new ReportTemplate({ year: { name: this.currentYear.toString(), code: this.currentYear } });
            this.submitted = false;
        },

        async checkFileForUpload() {
            try {
                let fileArray = [];
                let resultArray = [];

                if (this.uploadFile.file) {
                    if (this.uploadFile.file.uri) {
                        resultArray.push(this.uploadFile);
                    }
                    else {
                        const fD = new FormData();
                        fD.append('file', this.uploadFile.file);
                        fileArray.push(uploadFile(fD).then(({ data }) => resultArray.push(data[0])));
                    }
                }

                await Promise.all(fileArray);
                return resultArray;
            } catch (error) {
                this.$requestError(error.message);
            }
        },

        async getData() {
            this.$emit('loadingChange', true);
            try {
                const { data, included, meta } = await getReportTemplates({
                    formId: this.itemId,
                    page: this.currentPage,
                    pageSize: this.pageSize
                });
                this.reportTemplates = jsonApiListParser(data, included);
                this.totalRecords = meta.pagination.total;

                this.reportTemplates.forEach(i => {
                    const { active } = i;
                    if (active) {
                        this.$set(i, 'status', 'active');
                    }
                    else {
                        this.$set(i, 'status', 'inactive');
                    }
                })
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange');
            }
        },

        editReportTemplate() {
            this.currentDialogMode = 'EDIT';
            this.formData = new ReportTemplate(this.selectedRowItem);
            this.formData.year = this.years.find(item => item.code === this.selectedRowItem.year);
            if (this.selectedRowItem.file) {
                this.uploadFile = {
                    label: 'Загрузить файл',
                    id: this.selectedRowItem.file.id,
                    file: this.selectedRowItem.file,
                };
            } else {
                this.uploadFile = {
                    label: 'Загрузить файл',
                    id: uuidv4(),
                    file: null,
                };
            }
            this.showDialog = true;
        },

        rowClicked({ data }) {
            this.currentReportId = data.id;
            this.editReportTemplate();
        },

        toggleRowMenu(event, slotProps) {
            const { data: { id } } = slotProps;
            if (id !== this.currentReportId) {
                this.$refs[`listMenu${ this.currentReportId }`] && this.$refs[`listMenu${ this.currentReportId }`].hide(event);
            }
            this.currentReportId = id;
            this.$refs[`listMenu${ id }`].toggle(event);
        },

        getStatus (active) {
            const status = this.formTemplateStatuses.find(item =>  item.value === (active ? 'active' : 'inactive'));

            return status || {}
        },

        showErrors(data) {
            return data.$errors.length ? data.$errors : data.$silentErrors;
        }
    }
};
</script>

<style scoped lang='scss'>
::v-deep {
    .p-dialog {
        &-content {
            padding-top: 20px!important;
            .p-field {
                label {
                    margin-bottom: 4px;
                }
            }
        }
    }
}
.report-templates-list {
    .p-datatable {
        &-header {
            .table-global-search {
                margin-top: 0 !important;
            }
        }
    }

    ::v-deep {
        .header, .body {
            &-year {
                width: 120px;
            }
            &-status {
                width: 160px;
            }
            &-date-update {
                width: 120px;
            }
            &-name {
                width: 100%;
            }
        }
    }
}

::v-deep .uploadItem {
    .p-button-raised,
    .p-button.p-button-danger:enabled:focus {
        box-shadow: none;
    }

    .fileNameLength {
        max-width: 210px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.uploadInput {
    display: none;
}

.uploadwidth {
    width: 164px;
}

::v-deep .p-fileupload {
    margin-bottom: 16px;
}

::v-deep .p-dropdown .p-dropdown-label.p-placeholder {
    color: #8C8C8C;
}
</style>
